.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scan-logo {
    margin-top: 12%;
}

.scan-subtitle {
    text-align: center;
    margin-top: 8%;
    color: white;
    font-weight: bold;
}

.scan-container {
    top: -1px;
    padding-top: 1px;
    position: relative;
    height: calc(100vh + 1px);
    background-color: #249748;
}

.scan-container section {
    margin-top: 10%;
}

.scan-container section section div {
    border-width: 40px !important;
    box-shadow: #24974850 0px 0px 0px 5px inset !important;
}

.custom-modal {
    width: 80vw;
    height: auto;
    outline: 0;
    border-radius: .6rem;
    background-color: white;
    padding: 1rem;
    margin: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    overflow-y:scroll;
    padding: 20px 0;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal-container a {
    color: dodgerblue;
    text-decoration: none;
}

.ios-popup .safari-icon {
     width: 75px;
     height: 75px;
     margin: 4px auto 22px auto;
}

.ios-popup .safari-icon img {
    width: 100%;
    height: 100%;
}

.android-popup-text {
    margin-bottom: 4px;
}

.android-popup-link {
    margin-top: 8px;
    margin-bottom: 20px;
}

.modal-button {
    width: 100%;
    margin-top: 8px;
    padding: 10px;
    color: #249748;
    font-weight: bold;
    font-size: 14px;
    border-radius: 30px;
    border: 1px solid #249748;
    background-color: white;
}
